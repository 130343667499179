export const dashboard = {
  all_access: [
    { subject: 'dashboard', actions: 'load'},
    { subject: 'dashboard_report', actions: 'read'}
  ], 
  basic_access: [
    { subject: 'dashboard', actions: 'load'}
  ],
  no_access: []
}

export const items = {
  all_access: [
    { subject: 'items', actions: 'load'},
    { subject: 'item_add_all', actions: 'read'},
    { subject: 'item_bulk', actions: 'read'},
    { subject: 'item_bulk_action', actions: 'read'},
    { subject: 'item_detail_bulkAction', actions: 'read'},
    { subject: 'item_detail_item_action', actions: 'read'},
    { subject: 'item_detail_variant_edit', actions: 'read'},
    { subject: 'item_detail_variant_adjust', actions: 'read'},
    { subject: 'item_detail_variant_detail', actions: 'read'},
    { subject: 'item_detail_variant_detail_listing', actions: 'read'}
  ], 
  basic_access: [
    { subject: 'items', actions: 'load'},
  ],
  no_access: []
}

export const orders = {
  all_access: [
    { subject: 'orders', actions: 'load'},
    { subject: 'order_add_all', actions: 'read'},
    { subject: 'order_detail_order_action', actions: 'read'},
    { subject: 'order_bulk_action', actions: 'read'},
    { subject: 'order_check_all', actions: 'read'},
    { subject: 'order_check', actions: 'read'},
  ], 
  basic_access: [
    { subject: 'orders', actions: 'load'},
  ],
  no_access: []
}

export const customers = {
  all_access: [
    { subject: 'customers', actions: 'load'},
  ], 
  basic_access: [
    { subject: 'customers', actions: 'load'},
  ],
  no_access: []
}

export const reporting = {
  all_access: [
    { subject: 'reporting', actions: 'load'},
  ], 
  basic_access: [
    { subject: 'reporting', actions: 'load'},
  ],
  no_access: []
}

export const integration = {
  all_access: [
    { subject: 'integration', actions: 'load'},
  ], 
  basic_access: [
    { subject: 'integration', actions: 'load'},
  ],
  no_access: []
}

export const settings = {
  all_access: [
    { subject: 'settings', actions: 'load'},
  ], 
  basic_access: [
    { subject: 'settings', actions: 'load'},
  ],
  no_access: []
}

export const promotions = {
  all_access: [
    { subject: 'promotions', actions: 'load'},
  ], 
  basic_access: [
    { subject: 'promotions', actions: 'load'},
  ],
  no_access: []
}

export const analytics = {
  all_access: [
    { subject: 'analytics', actions: 'load'},
  ], 
  basic_access: [
    { subject: 'analytics', actions: 'load'},
  ],
  no_access: []
}

export const returns = {
  all_access: [
    { subject: 'returns', actions: 'load'},
  ], 
  basic_access: [
    { subject: 'returns', actions: 'load'},
  ],
  no_access: []
}

export const chats = {
  all_access: [
    { subject: 'chats', actions: 'load'},
  ], 
  basic_access: [
    { subject: 'chats', actions: 'load'},
  ],
  no_access: []
}

export const prints = {
  all_access: [
    { subject: 'prints', actions: 'load'},
  ], 
  basic_access: [
    { subject: 'prints', actions: 'load'},
  ],
  no_access: []
}