import { useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import MyAbility from '../../../../utils/ability'
import { client, forstokCookies } from '../../../../utils/forstok'

import { Can } from '../../../../components/can/can.component'

import { getStorage, setStorage } from '../../../../assets/javascripts/function'

import { getPermission } from '../../../../assets/javascripts/config'

import Auth from './auth'
import { scanUser } from '../log'
import { expiredUser } from '../logout'

const ProtectedRoute = (props) => {
  const forstokToken = forstokCookies.get('token')

  const users = getStorage('users', null, true)
  if(!users || !users?.profile_id || !users?.id || !users?.credentials) expiredUser()
  
  const [ isFirstLoad, setFirstLoad ] = useState(true),
        [ Authenticated, setAuthenticated ] = useState(forstokToken ? true : false),
        [ OrderV1Accessable, setOrderV1Accessable ] = useState(users.hasOwnProperty('has_access_orderV1') ? users.has_access_orderV1 : null)

  useEffect(() => {
    if(isFirstLoad) {
      if(Auth.isAuthenticated()) {
        scanUser().then((result) => {
          if(result) setAuthenticated(true)
          else setAuthenticated(false)
        }).catch(error => {
          console.error('Error Checking Login', error)
          if(!forstokToken) setAuthenticated(false)
        })
      }

      const getOrderAccess = async () => {
        const response = await fetch('https://core.forstok.com/api/v1/orderv1', {
            method: 'GET',
            headers: {
              'authorization': forstokToken ? `Bearer ${forstokToken}` : '',
              'Content-Type': 'application/json',
            }
          })
        const result = await response.json()
        let newUsers = Object.assign({}, users) 
        newUsers.has_access_orderV1 = result.has_access
        setStorage('users', JSON.stringify(newUsers), 'local')
        setOrderV1Accessable(result.has_access)
        return result
      }
  
      if(OrderV1Accessable === null) {
        getOrderAccess().catch(() => {
          let newUsers = Object.assign({}, users) 
          newUsers.has_access_orderV1 = false
          setStorage('users', JSON.stringify(newUsers), 'local')
          setOrderV1Accessable(false)
        })
      }

      const updateAvailibity = async () => {
        const permission = forstokCookies.get('permission') ? forstokCookies.get('permission') : ''
        if(permission && permission.length) {
          let results = permission.map(_permission => { 
            return {id: _permission.id, group: _permission.group, name: _permission.name}
          })
          MyAbility.update(getPermission(results))
        }
      }
      updateAvailibity().catch((error) => {
        console.log('Error update Availibity', error)
      })
      setFirstLoad(false)
    }
  }, [OrderV1Accessable, forstokToken, users, isFirstLoad])
  
  const { component: Component, as, sections, ...rest } = props   
  
  const ExcludeCan = [ 'chats', 'activity', 'prints' ]

  return !isFirstLoad && (
    <ApolloProvider client={client}>
      <Route  
        {...rest}
        render={props => {
          if(Authenticated) {
            if(!MyAbility) return <Component {...props} sections={sections}/> 
            else {
              return ExcludeCan.includes(as) ? <Component {...props} sections={sections}/> : ( <Can I='load' a={as} ability={MyAbility} passThrough>
                { can => {
                    return (can || as === 'dashboard') ? <Component {...props} sections={sections} can={can}/> : <Redirect exact to='/dashboard/home'/>
                  } 
                }
              </Can> )
            }
          }else expiredUser()
        }}
      />
    </ApolloProvider>
  )
}

export default ProtectedRoute