import { gql } from '@apollo/client'

import { QUERY_GET_LIST_ACCOUNTS} from './graphql'

export const typeDefs = gql`
  extend type Mutation {
    ToogleAccountListClient(updates: updates!): [Accounts]
  }
`

export const resolvers = {
  Mutation: {
    toogleAccountListClient: async(_, {updates}, {cache}) => {
      let newAccounts = []
      try {
        const { accounts } = cache.readQuery({
          query: QUERY_GET_LIST_ACCOUNTS
        })
        newAccounts = Array.from(accounts)
        if(updates) {
          let newAccountsMap = newAccounts.map(acc => {
            const _acc = Object.assign({}, acc) 
            const updatesIdx = updates.findIndex(upd => upd.channel_association_id === parseInt(acc.id))
            if(updatesIdx >= 0) _acc.isSyncing = updates[updatesIdx].is_syncing
            return _acc
          })
          newAccounts = newAccountsMap
          cache.writeQuery({
            query: QUERY_GET_LIST_ACCOUNTS,
            data: { accounts: newAccountsMap }
          })
        }
      } catch (e) {
        console.log(e, "ToogleAccountListClient")
      }
      return newAccounts
    },
  }
}